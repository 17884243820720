;(function () {
    'use strict';

    var toggles = document.querySelectorAll('.js-changePasswordToggle .showOnClick > a');
    Array.prototype.forEach.call(toggles, function(toggle, i)
    {
        onClickOrTap(toggle, function(e)
        {
            e.preventDefault();
            var group = getClosest(toggle, '.showOnClick');
            if (typeof group !== 'undefined' && group !== null) {
                if (group.classList) {
                    if (group.classList.contains('is-open')) {
                        group.classList.remove('is-open');
                    }
                    else {
                        group.classList.add('is-open');
                    }
                }
            }

        });
    });
}());
